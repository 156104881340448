<template>
  <div class="d-flex">
    <div>
      <v-btn
        v-if="member.joinStatus === 'INVITED'"
        icon
        small
        color="primary"
        @click.stop="resendInvitation([member.userId])"
      >
        <v-icon title="알림 재전송">mdi-bell-circle-outline</v-icon>
      </v-btn>

      <v-btn
        v-if="member.joinStatus === 'PENDING'"
        icon
        small
        color="primary"
        @click.stop="approveProjectMembers([member.userId])"
      >
        <v-icon title="승인">mdi-plus-circle-outline</v-icon>
      </v-btn>

      <v-btn icon small @click.stop="deleteProjectMembers([member.userId])">
        <v-icon color="grey" title="삭제">mdi-close-circle-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    member: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions("flowProjects", [
      "resendInvitation",
      "approveProjectMembers",
      "deleteProjectMembers"
    ])
  }
};
</script>
