<template>
  <div class="cr-first-row">
    <Name v-bind="$props" />
    <Actions v-bind="$props" v-on="$listeners" />
  </div>
</template>

<style lang="scss" scoped>
.cr-first-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<script>
import Name from "./Name.vue";
import Actions from "./Actions.vue";

export default {
  components: { Name, Actions },
  props: {
    member: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
